/*
 * @Author: your name
 * @Date: 2020-12-25 19:22:45
 * @LastEditTime: 2021-01-15 13:50:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  name: 'Home',
  component: () => import(/* webpackChunkName: "home" */ '../views/home/Home'),
  redirect: '/service',
  children: [
    {
      path: '/setting',
      name: 'Setting',    //聊天设置
      component: () => import(/* webpackChunkName: "home" */ '../views/home/Setting'),
    },
    {
      path: '/service',
      name: 'service',    //对话平台
      component: () => import('../views/chatService/chatPlatform/chatPage')
    },
    {
      path: '/history',
      name: 'history',   //聊天记录
      component: () => import('../views/chatService/chatHistory')
    },
    {
      path: '/autoReply',
      name: 'autoReply', //欢迎语
      component: () => import('../views/chatService/autoReply.vue')
    },
    {
      path: '/restReply',
      name: 'restReply', //休息回复
      component: () => import('../views/chatService/restReply.vue')
    },
    {
      path: '/quickReply',
      name: 'quickReply',//快捷回复
      component: () => import('../views/chatService/quickReply.vue')
    },
    {
      path: '/casualQues',
      name: 'casualQues',//常见问题
      component: () => import('../views/chatService/casualQues')
    },
    {
      path: '/autoQuestion',
      name: 'autoQuestion', //自动问答
      component: () => import('../views/chatService/autoQuestion.vue')
    },
    {
      path: '/switchList',
      name: 'switchList', //转接记录
      component: () => import('../views/chatService/switchList.vue')
    },
    {
      path: '/statistics',
      name: 'statistics', //客服统计
      component: () => import('../views/chatService/statistics.vue')
    }
  ],
},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
