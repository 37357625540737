/**
 *  项目的配置文件
 */

// 生产环境
export const apiUrl = 'http://gw.16888app.com.cn/' // 接口请求地址
export const chatUrl = 'ws://im.16888app.com.cn' // 客服地址
export const sourceUrl = 'http://mallmanage.16888app.com.cn/' // 来源地址，当用户失去登录态时需跳转到来源登录页
export const pcUrl = 'http://mallapp.16888app.com.cn/' // 没有PC终端，这里无需配置，直接留空即可

// 测试环境
// export const apiUrl = 'http://gw-t.16888app.com.cn/' // 测试环境是https
// export const chatUrl = 'ws://im-t.16888app.com.cn/' // 测试环境是wss
// export const sourceUrl = 'http://mallmanage-t.16888app.com.cn/'
// export const pcUrl = 'http://mallapp-t.16888app.com.cn/' // 没有PC终端，这里无需配置，直接留空即可

// 开发环境
// export const apiUrl = 'http://192.168.0.102:9009/'
// export const chatUrl = 'ws://192.168.0.102:9013/'
// export const sourceUrl = 'http://192.168.0.210:8105/'
// export const pcUrl = 'https://mallapp-t.yykj888.com/' // 没有PC终端，这里无需配置，直接留空即可

export const curLang = 'zh'; // 当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v2.6 *** date-2023-04-20 ***主版本v2.6**/
